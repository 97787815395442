import React from 'react';
import styled from 'styled-components';
import Flex, { FlexItem } from 'styled-flex-component';

import Link from './Link';

const Title = styled.span`
  color: #ffffff;
  font-weight: 500;
  text-align: center;
`;

const Item = ({ icon, title, link, size }) => (
  <Link to={link} external>
    <Flex
      center
      css={`
        width: ${(size === 'sm' && '100') || 250}px;
        margin-top: 1rem;
        margin-bottom: 1rem;
        margin-left: 0.25rem;
        margin-right: 0.25rem;
        padding-top: 0.5em;
        padding-bottom: 0.5em;
        border-radius: 6px;
        background-color: #707070;
      `}
    >
      <Flex center>{icon}</Flex>
      <Flex center>
        <Title size={size}>{title}</Title>
      </Flex>
    </Flex>
  </Link>
);

const Line = ({ leftTitle, leftLink, rightTitle, rightLink, size }) => (
  <Flex justifyCenter>
    <FlexItem>
      <Item title={leftTitle} link={leftLink} size={size} />
    </FlexItem>
    <FlexItem>
      <Item title={rightTitle} link={rightLink} size={size} />
    </FlexItem>
  </Flex>
);

const DlApp = ({ size }) => (
  <div>
    <Line
      size={size}
      leftTitle="Google Play"
      leftLink="https://play.google.com/store/apps/details?id=com.google.android.apps.tachyon&pcampaignid=web_share"
      rightTitle="App Store"
      rightLink="https://apps.apple.com/jp/app/google-meet/id1096918571"
    />
  </div>
);

export default DlApp;
