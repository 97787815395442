import React from 'react';

import { useTrackedState } from '../lib/state';

import SubLayout from '../components/SubLayout';
import { P } from '../components/common/P.style';
import { A } from '../components/common/Link.style';
import { Img } from '../components/common/Img.style';
import { H3, H4 } from '../components/common/H.style';
import { UL } from '../components/common/UL.style';
import { LI } from '../components/common/LI.style';
import Link from '../components/Link';
import Card from '../components/Card';
import DlApp from '../components/DlApp';
import ListItem from '../components/ListItem';
import ListItemBig from '../components/ListItemBig';
import { ContentWrapper } from '../components/common/ContentWrapper.style';
import ButtonList from '../components/ButtonList';
import Anchor from '../components/Anchor';
import bana from '../assets/img/telemed-link.jpg';
import mebana from '../assets/img/kenshinBana.png';

const FrequentlyAskedQuestions = () => {
  const { size } = useTrackedState();
  return (
    <SubLayout topTitle="よくあるご質問（FAQ）">
      <Anchor id="faq"></Anchor>
      <ul>
        <li>
          <Link to="#1">外来は何時までの診察になりますか？</Link>
        </li>
        <li>
          <Link to="#2">～～～といった症状が改善するためにはどのようにすれば良いでしょうか？</Link>
        </li>
        <li>
          <Link to="#3">
            初診ですが、当日の受付は可能ですか？また、受付時間内でしたら当日の診察は可能でしょうか？
          </Link>
        </li>
        <li>
          <Link to="#4">
            傷病手当金の申請にあたりこちらから指定様式と返信用封筒を送付するので対応できますか？
          </Link>
        </li>
        <li>
          <Link to="#5">
            書類（診断書、同意書、傷病手当金申請書、労災書類、通院証明書等）の発行は可能か？
          </Link>
        </li>
        <li>
          <Link to="#6">受診したいのですが、こちらは予約可能でしょうか？</Link>
        </li>
        <li>
          <Link to="#7">
            予約なしで受診し、腹部超音波検査が必要と判断された場合、当日に検査受けられますか？
          </Link>
        </li>
        {/*
      <li>
        <Link to="#8">コロナ陽性でなくても診察は受けられますか？</Link>
      </li>
      */}
        <li>
          <Link to="#9">
            スペインへの長期留学を予定しておりビザを申請するための診断書の発行はできますか？
          </Link>
        </li>
        <li>
          <Link to="#10">新型コロナ後遺症外来について詳しく知りたい。</Link>
        </li>
        <li>
          <Link to="#25">PCR検査について詳しく知りたい。</Link>
        </li>
        <li>
          <Link to="#26">健康診断について詳しく知りたい。</Link>
        </li>

        <li>
          <Link to="#11">オンライン診療について詳しく知りたい。</Link>
        </li>
        <li>
          <Link to="#12">オンライン診療は予約制ですか？</Link>
        </li>
        <li>
          <Link to="#13">オンライン診療で睡眠薬は処方できますか？</Link>
        </li>
        <li>
          <Link to="#14">オンライン診療で写真添付は申し込み時じゃないとだめか？</Link>
        </li>
        <li>
          <Link to="#15">
            初めてオンライン診療の申し込みをしたのに、受付完了のメールがとどかない。
          </Link>
        </li>
        <li>
          <Link to="#16">オンライン診療のGoogle Meetによる診察はどのようなものですか？</Link>
        </li>
        <li>
          <Link to="#17">
            オンライン診療のGoogle Meetによる診察は、最初にURLの案内が届きますか？
          </Link>
        </li>
        <li>
          <Link to="#18">オンライン診療で医師からの着信に気づけなかった。</Link>
        </li>
        <li>
          <Link to="#19">オンライン診療を受診したが請求のメールが届かない。</Link>
        </li>
        <li>
          <Link to="#20">オンライン診療の支払いはどのようにしますか？</Link>
        </li>
        <li>
          <Link to="#21">オンライン診療の支払いの後の流れは？</Link>
        </li>
        <li>
          <Link to="#22">
            オンライン診療を受診し、診断書の発行申込をして支払いも済ませたが書類が届いていない。
          </Link>
        </li>
      </ul>

      <Card>
        <Anchor id="1"></Anchor>
        <H4>外来は何時までの診察になりますか？</H4>
        <P>
          受付時間内に来院された方の診療が終わるまでです。
          <br />
          受付時間は下記ページをご参照ください。
          <br />
          <a href="https://www.hirahata-clinic.or.jp/schedule">
            https://www.hirahata-clinic.or.jp/schedule
          </a>
        </P>
      </Card>

      <Card>
        <Anchor id="2"></Anchor>
        <H4>～～～といった症状が改善するためにはどのようにすれば良いでしょうか？</H4>
        <P>メールではお答えしかねるので一度受診をお願いします。</P>
      </Card>

      <Card>
        <Anchor id="3"></Anchor>
        <H4>
          初診ですが、当日の受付は可能ですか？また、受付時間内でしたら当日の診察は可能でしょうか？
        </H4>
        <P>受付時間内にお越しいただければ当日の診察可能です。</P>
      </Card>

      <Card>
        <Anchor id="4"></Anchor>
        <H4>
          傷病手当金の申請にあたりこちらから指定様式と返信用封筒を送付するので対応できますか？
        </H4>
        <P>
          当院では、書類のみの対応は致しかねます。
          <br />
          原本をご持参の上、診療をご受診いただくか、事前にご郵送いただき、オンライン診療のお申し込みをお願いいたします。
        </P>
      </Card>

      <Card>
        <Anchor id="5"></Anchor>
        <H4>書類（診断書、同意書、傷病手当金申請書、労災書類、通院証明書等）の発行は可能か？</H4>
        <P>
          当院では書類の発行のみは対応いたしかねます。
          <br />
          必ず診察をご受診ください。
          <br />
          <br />
          <b>取り扱い書類</b>
          <UL>
            <LI>普通診断書（当院書式）</LI>
            <LI>診断書（規定様式）</LI>
            <LI>各種証明書</LI>
            <LI>傷病手当金申請書</LI>
            <LI>労災指定用紙</LI>
          </UL>
          ※ 以下の二種類は対面診療でのみ発行可能です。
          <UL>
            <LI>同意書（はり・きゅう）</LI>
            <LI>同意書（マッサージ）</LI>
          </UL>
          ※ その他書類に関してはメールにてお問合せください。
          <br />
        </P>
        <P>
          <b>各種書類の原本をお持ちの方</b>
          <UL>
            <LI>
              <b>外来診療：</b>
              <br />
              診察時必ずご持参ください。
            </LI>
            <LI>
              <b>オンライン診療：</b> <br />
              事前にご郵送いただき、オンライン診療のお申し込みをお願いいたします。
            </LI>
          </UL>
          　※郵便物の到着に1週間ほどかかる場合がございます。
          <br />
          　投函後ゆとりをもってお申し込みをお願い申し上げます。
        </P>
      </Card>

      <Card>
        <Anchor id="6"></Anchor>
        <H4>受診したいのですが、こちらは予約可能でしょうか？</H4>
        <P>
          当院では健康診断、内科外来、PCR検査など多数の種類を取り扱いしている為、何のご予約を希望がご教示いただけますと幸いです。
        </P>
      </Card>

      <Card>
        <Anchor id="7"></Anchor>
        <H4>予約なしで受診し、腹部超音波検査が必要と判断された場合、当日に検査受けられますか？</H4>
        <P>当院では検査は完全予約制となっております。</P>
      </Card>
      {/*
    <Card>
      <Anchor id="8"></Anchor>
      <H4>コロナ陽性でなくても診察は受けられますか？</H4>
      <P>診察できます。</P>
    </Card>
      */}
      <Card>
        <Anchor id="9"></Anchor>
        <H4>スペインへの長期留学を予定しておりビザを申請するための診断書の発行はできますか？</H4>
        <P>
          可能です。
          <br />
          予約制で承っておりますので、メールにて下記情報をお送りください。
          <br />
          <UL>
            <LI>お名前</LI>
            <LI>生年月日</LI>
            <LI>ご自宅の住所</LI>
            <LI>ご希望の日時</LI>
            <LI>パスポートの写真の添付</LI>
            <LI>診断書のPDFもしくは診断書の写真の添付</LI>
          </UL>
        </P>
      </Card>

      <Card>
        <Anchor id="10"></Anchor>
        <H4>新型コロナ後遺症外来について詳しく知りたい。</H4>
        <P>
          こちらのページをご参照ください。
          <br />
          <br />
          <a
            href="https://www.hirahata-clinic.or.jp/covid19/"
            css={`
              border: 3px solid #f7b13c;
              border-radius: 6px;
              background-color: #fffff0;
              padding: 0.5em;
              margin: 0.5em;
            `}
          >
            コロナ後遺症外来
          </a>
        </P>
      </Card>
      <Card>
        <Anchor id="25"></Anchor>
        <H4>PCR検査について詳しく知りたい。</H4>
        <P>
          こちらのページをご参照ください。
          <br />
          <br />
          <a
            href="https://www.hirahata-clinic.or.jp/covid-pcr/"
            css={`
              border: 3px solid #f7b13c;
              border-radius: 6px;
              background-color: #fffff0;
              padding: 0.5em;
              margin: 0.5em;
            `}
          >
            PCR検査
          </a>
        </P>
      </Card>

      <Card>
        <Anchor id="26"></Anchor>
        <H4>健康診断について詳しく知りたい。</H4>
        <P
          css={`
            text-align: center;
          `}
        >
          こちらのサイトをご参照ください。
          <br />
          <Link to="https://www.hirahata.net/" external>
            <Img
              src={mebana}
              css={`
                width: ${(size === 'sm' && '100') || 50}%;
              `}
            />
          </Link>
        </P>
      </Card>

      <H3>オンライン診療関連</H3>
      <Card>
        <Anchor id="11"></Anchor>
        <H4>オンライン診療について詳しく知りたい。</H4>
        <P
          css={`
            text-align: center;
          `}
        >
          こちらのサイトをご参照ください。
          <br />
          <Link to="https://telemed-app.hirahata-clinic.or.jp/?locate=shibuya" external>
            <Img
              src={bana}
              css={`
                width: ${(size === 'sm' && '100') || 50}%;
              `}
            />
          </Link>
        </P>
      </Card>
      <Card>
        <Anchor id="12"></Anchor>
        <H4>オンライン診療は予約制ですか？</H4>
        <P>
          オンライン診療は予約制ではないので、日時の指定はお受けできません。
          <br />
          受診したい日の受付時間内にお申込みいただくと、医師が順番に診察していきます（場合により診察前に鍼灸師・薬剤師による問診のお電話がいきます）。
          <br />
          多数のお申込み等でその日に受診ができなかった場合は、大変恐縮ですがキャンセルとなりますので、別の日に再度お申込みをお願いいたします。
          <br />
          再お申込み時に体調やご要望に変わりがなければ、お申込みフォームの「前回申し込んだが診察を受けられなかった方」にチェックを入れていただくと入力項目が省略されますのでご利用ください。
          <br />
          受診できなかった回数が多い方を優先して診察しております。
        </P>
      </Card>
      <Card>
        <Anchor id="13"></Anchor>
        <H4>オンライン診療で睡眠薬は処方できますか？</H4>
        <P>
          当院の外来で処方をうけたことのない睡眠薬については、処方ができません。
          <br />
          当院で処方をうけたことがあり、医師の診断がおりた場合に限り、処方されます。
        </P>
      </Card>

      <Card>
        <Anchor id="14"></Anchor>
        <H4>オンライン診療で写真添付は申し込み時じゃないとだめか？</H4>
        <P>
          申し込み時容量が重くなり、送信が遅れる、手間がかかるなどの場合は、一度お申込みをしていただいて、写真のみ後からメールでお送りいただいても結構です
        </P>
      </Card>

      <Card>
        <Anchor id="15"></Anchor>
        <H4>初めてオンライン診療の申し込みをしたのに、受付完了のメールがとどかない。</H4>
        <P>初診の方は、初期登録がありますので、少し時間がかかります。</P>
      </Card>

      <Card>
        <Anchor id="16"></Anchor>
        <H4>オンライン診療のGoogle Meetによる診察はどのようなものですか？</H4>
        <P>
          Google Meetの『ビデオ通話』機能を利用しての診察となります。
          <br />
          診察時に医師からGoogle
          Meetのビデオ電話がかかってきますので、応答ボタンを押してビデオ通話を開始し てください。
          <br />
          スマホの通知の設定で、Google
          Meetの通知を許可しておくと、電話と同じように着信音が鳴るようになりま す。
          <br />
          待機中にアプリを常に立ち上げておく必要はありません。
          <br /> <br />
          アプリのダウンロードは各アプリストアより行なってください。
          <DlApp size={size} />
          <br />
          事前にGoogle Meetの「通話用アカウント」の登録をお願いいたします。
          <br />
          登録方法は下記リンクをご参照ください。
          <UL>
            <LI>
              <Link
                to="https://support.google.com/meet/answer/12387958?hl=ja&co=GENIE.Platform%3DAndroid"
                external
              >
                Android
              </Link>
            </LI>
            <LI>
              <Link
                to="https://support.google.com/meet/answer/12387958?hl=ja&co=GENIE.Platform%3DiOS&oco=0"
                external
              >
                iPhone / iPad
              </Link>
            </LI>
          </UL>
        </P>
      </Card>

      <Card>
        <Anchor id="17"></Anchor>
        <H4>オンライン診療のGoogle Meetによる診察は、最初にURLの案内が届きますか？</H4>
        <P>
          Google Meetをビデオ会議等でご利用される時と違いURLのご案内はありません。
          <br />
          診察時に医師からGoogle
          Meetのビデオ電話がかかってきますので、応答ボタンを押してビデオ通話を開始し てください。
          <br />
          スマホの通知の設定で、Google
          Meetの通知を許可しておくと、電話と同じように着信音が鳴るようになります。
          <br />
          待機中にアプリを常に立ち上げておく必要はありません。
          <br />
          ※事前にGoogle Meetの「通話用アカウント」の登録をお願いいたします。
        </P>
      </Card>

      <Card>
        <Anchor id="18"></Anchor>
        <H4>オンライン診療で医師からの着信に気づけなかった。</H4>
        <P>
          何度かご連絡しておりますので、そのままお待ちください。
          <br />
          深夜の場合はメールが届きますので、内容をご確認ください。
        </P>
      </Card>

      <Card>
        <Anchor id="19"></Anchor>
        <H4>オンライン診療を受診したが請求のメールが届かない。</H4>
        <P>
          オンライン診療の請求は当院受付スタッフが行っております。
          <br />
          来院されている方の対応が優先となりますので、ご請求にタイムラグが生じる場合がございます。
          <br />
          また、迷惑メールとして振り分けられている可能性もございます。
          <br />
          再送信もできますので、info@hirahata-clinic.or.jp 宛にメールでお問い合わせください。
        </P>
      </Card>

      <Card>
        <Anchor id="20"></Anchor>
        <H4>オンライン診療の支払いはどのようにしますか？</H4>
        <P>
          診察終了後、請求のご案内のメールを送らせていただきます。
          <br />
          メール内のリンクから決済をお願いします。
        </P>
      </Card>

      <Card>
        <Anchor id="21"></Anchor>
        <H4>オンライン診療の支払いの後の流れは？</H4>
        <P>
          <UL>
            <LI>
              <b>処方箋の受け取り方法が「とどくすり」の方：</b>
              <br />
              支払いの通知が当院に届き次第、当院からとどくすり宛に処 方箋をFAX・郵送いたします。
              <br />
              その後とどくすりから服薬指導の連絡があり、最終的に送料無料でお薬がご自宅に届きます。
            </LI>
            <LI>
              <b>処方箋の受け取り方法が「ご自宅に郵送」の方：</b>
              <br />
              支払いの通知が当院に届き次第、処方箋をご自宅へ郵送いたします。
              <br />
              届いた処方箋を薬局へご持参し、お薬をお受け取りください。
              <br />
              （いずれも調剤料、薬代などの料金がかかります）
            </LI>
          </UL>
        </P>
      </Card>

      <Card>
        <Anchor id="22"></Anchor>
        <H4>
          オンライン診療を受診し、診断書の発行申込をして支払いも済ませたが書類が届いていない。
        </H4>
        <P>
          郵便局の改定により、郵送物の到着が遅くなっております。
          <br />
          （参考）
          <a href="https://www.post.japanpost.jp/2021revision/">
            https://www.post.japanpost.jp/2021revision/
          </a>
        </P>
      </Card>
    </SubLayout>
  );
};

export default FrequentlyAskedQuestions;
